@import "../../../assets/colors.scss";



#logout_sidebar_button {
    position: absolute;
    display: inline-block;
    bottom: 0;
    left: 15px;
}

.sidebar {
    color: white;
    background:hsl(227, 49%, 89%);
    position: relative;
    min-height: 100%;
    max-width: 260px;
    padding-bottom: 40px;
}
.sidebar li {
    margin-left: -15px;
    margin-right: -15px;
}


aside {
    min-width: 240px;
}

.active {
    background: theme-color('light-blue');
}

.collapse {
    width: 100%;
}

.logout-border{
    border-top: 2px solid theme-color('secondary');
}