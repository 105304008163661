.card-container{
  border-radius: 20px;
  box-shadow: 0 10px 30px 0 rgba(209, 213, 223, 0.5);
  background-color: #ffffff;
  background-color: #ffffff;
  margin-bottom: 20px;
  transition: min-height 0.5s ease-in-out;
}

iframe {
  transition: height 0.5s ease-in-out;
}