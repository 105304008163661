@import "../../../assets/colors.scss";

.column-container{
    // width: 21.4vw;
    // min-width: 425px;
    height: 100%;
    // padding-bottom: 100px;
    background-color: $white;
    overflow-y: scroll;
    // padding-bottom: 10px;
    // margin-left: 1.51%;
    // margin-right: 1.51%;
    border-radius: 13px;
    font-family: MuliBold;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;

    .title{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $primary;
        // width: 70%;
        // height: 11vh;
        text-transform: uppercase;
        padding-top: 5%;
        padding-bottom: 5%;
    }

    ::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1; 
      }
       
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888; 
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
}