
@import "../../../assets/colors.scss";

.bg-video{
    position:absolute;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    object-fit: cover;
}
.container-login{
    z-index: 100;

    .form-container{
        z-index: 100;

        border-radius: 3.5vh;
        box-shadow: 0 16px 40px 0 rgba(112, 144, 176, 0.2);
        background-color: #fcf7f7;
    }
}


video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  @include media-breakpoint-up(lg) { 
    .container-login {
        .form-container {
            width: 34.9%;
            top: 17.1%; 
            right: 11.7%;
            position: absolute;
        }
    }
    
  }