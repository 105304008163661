@import "../../../../assets/colors.scss";

.form-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo{
        margin-top:3vh;
        width: 100%;
        // height: 10vh;
    }

    .form-text{

        width: 50%;
        height: 50%;
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        .form-title{
            font-family: 'Muli';
            font-size: 1.04vw;
            color: $primary;
            align-self: flex-start;
        }   

        .input-container{
            margin-top: 1vh;
            .label{
                margin-top: 1vh;
                align-self: flex-start;
                font-size: 0.72vw;
                color: $label;
                padding-bottom: 1vh;
            }
        }

        .input{
            width: 18.2vw;
            height: 5.5vh;
            border-radius: 5px;
            box-shadow: 0 5px 10px 0 #e0e4ee;
            border: none;
        }

        .s-button{
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            width: 13.2vw;
            height: 6.1vh;
            font-family: "MuliSemiBold";
            font-size: 2.08vw;
            border-radius: 25px;
            color:$white;
            cursor: pointer;
        }
        
    }
}