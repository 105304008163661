

@import "./assets/colors.scss";


body {

  overflow: scroll;
  background-color: theme-color('bg-color') !important;
  font-size: 15px;
  font-family: 'sans-serif';
}




@include media-breakpoint-up(lg) { 
  body {
    overflow: hidden;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}