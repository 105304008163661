//main app color
$primary:#1b5196;
$secondary:#fad830;

$white: #ffffff;
//login page
$label :#a4a4a4;

$label-form:#97a2af;

// $bg-color:rgba(112, 144, 176, 0.18);

$light-blue:#3f82c5;



$theme-colors: (
    "primary": #1b5196,
    "secondary": #fad830,
    "success": #44D7B6,
    "warning": #F7B500,
    "info": #41ADFF,
    "danger": #FF3863,
    "semi-light": #E2E2E6,
    "white": #ffffff,
    "label": #a4a4a4,
    "label-form": #97a2af,
    "bgcolor": #E5EBF1,
    "light-blue": #3f82c5

);

@import "~bootstrap/scss/_functions";

$body-color: theme-color("label");
$body-bg: theme-color("bgcolor");
$navbar-dark-color:  theme-color("white");
$navbar-dark-hover-color:   theme-color("white");
@import "~bootstrap/scss/bootstrap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body {
  width: 100%;
  height:100%;
}


@font-face {
    font-family: 'MuliBold';
    src:  url(./fonts/muli/Muli-Bold.ttf) format('truetype');
  }
  
  
  @font-face {
    font-family: 'MuliBoldItalic';
    src:  url(./fonts/muli/Muli-BoldItalic.ttf) format('truetype');
  }
  
  
  @font-face {
    font-family: 'MuliSemiBold';
    src:  url(./fonts/muli/Muli-SemiBold.ttf) format('truetype');
  }
  
  
  @font-face {
    font-family: 'MuliSemiBoldItalic';
    src:  url(./fonts/muli/Muli-Semi-BoldItalic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'MuliExtraLight';
    src:  url(./fonts/muli/Muli-ExtraLight.ttf) format('truetype');
  }
  @font-face {
    font-family: 'MuliExtraLightItalic';
    src:  url(./fonts/muli/Muli-ExtraLightItalic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'MuliItalic';
    src:  url(./fonts/muli/Muli-Italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'MuliLight';
    src:  url(./fonts/muli/Muli-Light.ttf) format('truetype');
  }
  @font-face {
    font-family: 'MuliLightItalic';
    src:  url(./fonts/muli/Muli-LightItalic.ttf) format('truetype');
  }
  
  @font-face {
    font-family: 'Muli';
    src:  url(./fonts/muli/Muli.ttf) format('truetype');
  }
  
  
  
  
  @font-face {
    font-family: 'OpenSansBold';
    src:  url(./fonts/Open_Sans/OpenSans-Bold.ttf) format('truetype');
  }
  
  
  @font-face {
    font-family: 'OpenSansBoldItalic';
    src:  url(./fonts/Open_Sans/OpenSans-BoldItalic.ttf) format('truetype');
  }
  
  
  @font-face {
    font-family: 'OpenSansExtraBold';
    src:  url(./fonts/Open_Sans/OpenSans-ExtraBold.ttf) format('truetype');
  }
  
  
  @font-face {
    font-family: 'OpenSansExtraBoldItalic';
    src:  url(./fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'OpenSansItalic';
    src:  url(./fonts/Open_Sans/OpenSans-Italic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'OpenSansLight';
    src:  url(./fonts/Open_Sans/OpenSans-Light.ttf) format('truetype');
  }
  @font-face {
    font-family: 'OpenSansLightItalic';
    src:  url(./fonts/Open_Sans/OpenSans-LightItalic.ttf) format('truetype');
  }
  @font-face {
    font-family: 'OpenSansRegular';
    src:  url(./fonts/Open_Sans/OpenSans-Regular.ttf) format('truetype');
  }
  @font-face {
    font-family: 'OpenSansSemiBold';
    src:  url(./fonts/Open_Sans/OpenSans-SemiBold.ttf) format('truetype');
  }
  @font-face {
    font-family: 'OpenSansSemiBoldItalic';
    src:  url(./fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf) format('truetype');
  }