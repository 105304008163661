@import "../../../assets/colors.scss";



.preview-page-icon{
  margin-bottom: 1px;
  margin-right: 3px;
  width: 20px;
  height: 19px;
}

.add-new-post-icon{
  margin-bottom: 1px;
  margin-right: 3px;
}

.overflow-x-scroll{
  overflow-x: scroll;
}

.all-posts-container{
  position: fixed;
  bottom: 0;
  height: 70%;
}
.collapse-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;

}

.disable {
  width: 100%;
  height: 100%;
  position: absolute;
}



.elements-container{
  transform: rotate(180deg);
}

.element-container{
  transform: rotate(180deg);
}

.all-posts-container{
  position: fixed;
  bottom: 0;
  height: 70%;
}
.collapse-btn {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;

}

.disable {
  width: 100%;
  height: 100%;
  position: absolute;
}

.card-container::-webkit-scrollbar {
  display: none;
}

.iframe::-webkit-scrollbar {
  display: none;
}

.card-container, iframe {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}