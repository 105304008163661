@import "../../../assets/colors.scss";

 
.form-container{
  background-color: theme-color('white');
  border-radius: 15px;
}

.addpost-form {
  font-size: 18px;
  font-weight: 500;
}

.h-300 {
  height: 300px;
}
.submission-success-wrapper {
  border-radius: 20px;
  box-shadow: 5px 5px 19px 2px #aaa;
}
